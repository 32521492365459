.event-details-section {
    background-color: #fff;
    padding: 35px 40px;
    margin-bottom: 5px;
}

.event-details-section-header {
    margin-bottom: 20px;
}

.event-details-section-header-content {
    font-family: Mulish;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.events-added-by-bold {
    font-family: Mulish;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: #3a4354;
}

.event-details-section-location > div {
    margin-bottom: 22px;
}

.event-details-section-participants > div:first-child {
    margin-bottom: 12px;
}
.event-details-section-location > div:last-child {
    margin-bottom: 0;
}
.event-location-content {
    font-family: Mulish;
    font-size: 12px;
    line-height: normal;
    letter-spacing: normal;
    color: #3a4354;
}

.events-description-margin {
    margin-left: 11px;
}
.events-date-margin {
    margin-left: 11px;
    margin-right: 84px;
}
.event-participant-photo-conatiner > div {
    margin-right: 14px;
    margin-top: 10px;
}
.event-participant-photo-conatiner {
    flex-wrap: wrap;
}
.events-created-by {
    padding-right: 8px;
    border-right: 1px solid #f3f4f8;
    margin-right: 8px;
}

.event-acceptance-circle {
    width: 8px;
    height: 8px;
    border: solid 1px #ffffff;
    border-radius: 50%;
    position: absolute;
    top: -1px;
    right: -1px;
    z-index: 1;
}

.event-accepted-color {
    background-color: #1cc219;
}

.event-declined-color {
    background-color: #eb1d2b;
}

.event-nores-color {
    background-color: #c4c4c4;
}

.event-loader_container {
    width: 100%;
    height: 80vh;
}

.showMore-events-details-content {
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: #3a4354;
    cursor: pointer;
}

.event_details_everyone {
    font-family: Mulish;
    font-size: 13px;
    font-weight: bold;
    color: #444444;
    text-align: left;
}
