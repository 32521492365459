@import '../../sass/main.scss';
.input__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
    position: relative;
}
.input__label {
    font-family: Mulish;
    font-size: 12px;
    color: #696969;
    margin-bottom: 5px;
}
.input__asterix {
    color: #eb1d2b;
}
.input__input {
    width: 100%;
    height: 45px;
    background-color: #ffffff;
    padding: 0 10px;
    font-size: 14px;
    // font-weight: bold;
    color: #3a4354;
    font-family: Mulish;
    border: none;
}

.input__textarea {
    width: 100%;
    border-radius: 3px;
    padding: 15px 10px;
    border: solid 1px #e8e8e8;
    background-color: #ffffff;
    font-size: 14px;
    // font-weight: bold;
    color: #3a4354;
    font-family: Mulish;
    border: none;
}
.input__input.error,
.input__textarea.error {
    border: solid 1px #f00;
}

.input__error-text {
    padding: 9px 0px;
    font-size: 12px;
    line-height: 16px;
    // font-weight: bold;
    color: #f00;
    font-family: Mulish;
    // position: absolute;
    // bottom: -2rem;
}

// /* The container */
.label-container {
    position: relative;
    padding-top: 0.3em;
    padding-right: 3em;
}

[type='radio'][disabled] + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -3px;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #e8e8e8;
    cursor: default;
}

[type='radio']:not(:disabled) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -3px;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}

[type='radio']:checked,
[type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type='radio']:checked + label:before {
    content: '';
    position: absolute;
    left: 0;

    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #3d4b5b;
    position: absolute;
    top: 0px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
