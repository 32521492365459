@import '../../sass/main.scss';

.s_no {
    // padding-left: 25px;
}

.image_pic {
    height: 20px;
    width: 30px;
}

.pic_id_wrapper {
    display: flex;
    align-items: center;
}

.id_style {
    padding-left: 10px;
}

.candidate_loader_container {
    width: 100%;
    height: 80%;
    background-color: #fff;
}

.comments {
    // width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.timings_table {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.wrappingLetters {
    width: 22em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.row_min_width {
    min-width: 4.5em;
}

.topic_modal_reason {
    width: 25vw;
    padding: 1rem;
}

.input_fields_container_topic {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.error_topic_title {
    position: absolute;
    top: 8rem;
    left: 1.5rem;
    font-size: 10px;
    color: red;
    font-family: Mulish;
    top: 100px;
    left: 43px;
}

.input_field_wrapper_topic_button {
    // width: 30%;
    position: relative;
}

.button-component__topic {
    // height: 45px;
    // padding: 0 30px;
    // width: 70px;
    height: 33px;
    // padding: 17px 35px;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #666666;
    justify-content: center;
    align-items: center;
    font-family: Mulish;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
}

.button-component__topic:hover {
    // height: 45px;
    // padding: 0 30px;
    // width: 70px;
    height: 33px;
    // padding: 17px 35px;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #666666;
    justify-content: center;
    align-items: center;
    font-family: Mulish;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    opacity: 0.9;
}

.button-component__topic_secondary {
    background-color: #0058fa;
}

.input_topic_modal {
    // width: 200px;
    height: 39px;
    border-radius: 3px;
    border: solid 1px #e8e8e8;
    background-color: #ffffff;
    padding: 5px 10px;
    font-size: 14px;
    color: #3a4354;
    font-family: Mulish;
    width: 90%;
}

.topic_list_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #e8e8e8;
    padding-top: 15px;
    padding-bottom: 15px;
}

.topic_list_name {
    font-family: Mulish;
    font-size: 14px;
    color: #000000;
}

.topic_list_remove {
    font-family: Mulish;
    font-size: 12px;
    color: #0058fa;
    cursor: pointer;
    font-weight: bold;
}

.padding_no_topics {
    padding-top: 10px;
    padding-bottom: 10px;
}
