.error-cont {
    outline: none;
    padding: 20px;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    width: clamp(300px, 60%, 600px);
}

.something-text {
    margin: 0 0 10px;
    font-family: Mulish;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-two);
}
.brace-text {
    font-family: Mulish;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #404b5b;
}

.text-wrapper {
    // position: relative;
    // top: -15%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
