@import '../../../../../../sass/main.scss';
@import '../../../../../Register/AddEmployeeDetails/AddEmployeeTab/AddEmployeeTab.scss';
@import '../../../../../Feedback/components/FeedbackDetails/FeedbackDetails.scss';

.add_policy_container {
    background-color: #fff;
    padding: 3.5rem;
    overflow: hidden;
}

.add_policy_date_wrap {
    // width: 39%;
    width: 41%;
    // margin-right: 5px;
}

.add_policy_category_wrap {
    // width: 43%;
    // margin-left: 31px;
    // width: 47.3%;
    width: calc(100% - 41% - 60px);
}

.bottom_space {
    margin-bottom: 10px;
    padding-bottom: 5px;
}
.input_field_full_wrapper_addPolicy {
    width: 52.7%;
}
.addPolicyAttachment {
    width: 52.7%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.max1doc {
    font-family: Mulish;
    font-size: 12px;
    color: #696969;
}
.setupPolicyAttachmentContainer {
    // border: 1px solid blue;
    margin-top: -12px;
}
.uploaded_file_preview_setupAddPolicy {
    width: 52.7%;
    margin-top: 0.3em;
    background-color: rgba(0, 88, 250, 0.06);
    border: 1px solid #0058fa;
    padding: 1em;
}
.upload_files_input_wrapperNeW {
    width: 100%;
    height: 8em;
    border: dashed 1px #99b7eb;
    //  background-color: #eef4ff;
    padding: 2em;
    position: relative;
    cursor: pointer;
    // opacity: 0.24;
    // opacity: 0.42;
    // border-radius: 3px;
    //   border: solid 1px #0058fa;
    background-color: rgba(0, 88, 250, 0.06);
}
.cursor-notAllowed {
    cursor: not-allowed;
}
.word-break {
    word-break: break-word;
    hyphens: auto !important;
}
.cancelUpload_icon_setupAddPolicy {
    width: 0.833em;
    height: 0.833em;
    cursor: pointer;
    margin-left: 10px;
}
