@import '../../sass/main.scss';

.action_icon_container_policy {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.policy_listing__table td {
    font-size: 14px;
    color: #3a4354;
}
.policy_listing__table td:first-child {
    padding-left: 1.9em;
}
.policy_listing__table td:nth-child(2),
.policy_listing__table td:nth-child(4),
.policy_listing__table td:nth-child(6) {
    font-family: Mulish;
    font-size: 14px;
    // color: #0058fa;
}
