@import '../../sass/main.scss';

.employee_container {
    width: 100%;
    height: 100%;
    padding: 0em 3.58em 8.084em 3.58em;
    &__content_header_wrapper {
        @include flex__start;
        margin-top: 2.91em;
        margin-bottom: 1.66em;
        width: 100%;
    }
}

.action_icon_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 10%;
}

.show_more_container {
    width: 100%;
    font-family: Mulish;
    font-size: 1em;
    font-weight: 600;
    color: #0058fa;
    text-align: center;
    cursor: pointer;
    margin-top: 0.5em;
}
.showMore_loader {
    width: 100%;
    text-align: center;
}

.showmore_loader_img {
    width: 2.5em;
    height: 2.5em;
}
.name_email_container_text {
    font-family: Mulish;
    font-size: 16px;
    color: #404b5b;
}
.name_email_container_emailtext {
    font-family: Mulish;
    font-size: 0.85em;
    line-height: normal;
    color: #929292;
}
// .name_container_text {
//     //    margin-left: -1.5em;
// }
.admin-table-wrapper {
    height: calc(87% - 70px);
}
.admin-table-wrapper > .tableWrap {
    height: 100%;
}

.fix_mobile_cell {
    white-space: nowrap;
    padding: 0 10px 0 0;
}

.archieve_listing_table td {
    font-size: 14px;
    color: #3a4354;
}

.archieve_listing_table td:nth-child(2) {
    font-family: Mulish;
    font-size: 14px;
}
.archieve_listing_table .table_header_content:last-child {
    text-align: center;
    padding-left: 30px;
}
.archieve-action-icon {
    justify-content: center;
}
