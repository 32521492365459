@import '../../../../sass/main.scss';
* {
    box-sizing: border-box;
    scroll-behavior: smooth;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}
*::-webkit-scrollbar {
    width: 0 !important;
}
.polls-page__main-container {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: solid 1px #e8e8e8;
}
p {
    font-family: Mulish;
    font-size: 1em;
}
h1 {
    font-family: Mulish;
    font-size: 1.2em;
}
h1:first-letter {
    text-transform: capitalize;
}

.new-text {
    font-family: Mulish;
    color: #0058fa;
}
.polls-page__poll-wrapper {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    width: 100%;
    cursor: pointer;
}
.polls-page__poll-container {
    border-left: 2px solid #ffffff;
    background-color: #ffffff;
    width: inherit;
}
.triangle_right_point {
    width: 0;
    height: 0;
    border-top: 5.4rem solid transparent;
    border-left: 1.67rem solid #ffffff;
    border-bottom: 5rem solid transparent;
}
.polls-page__poll-container.active {
    border-left: 2px solid #0058fa;
}
.polls-page__right-content {
    flex-wrap: wrap;
}
.polls-page__left-content {
    padding-bottom: 2em;
}
.full-width {
    width: 100%;
}
.polls-page__polls-list-container {
    width: 60%;
    max-height: calc(100vh - (14.07em + 44px));
    overflow-y: scroll;
}

.show_more_polls_container {
    width: 60%;
    max-height: calc(100vh - (14.07em +44px));
    overflow-y: scroll;
}
.polls-page__chart-container {
    width: 40%;
}
.half-width {
    width: 50%;
}
.background-white {
    background-color: #ffffff;
}
.polls-page__item-separater {
    margin-right: 5px;
    padding-right: 5px;
    border-right: solid 1px #696969;
}
.question-padding {
    padding-bottom: 0.5em;
}
.poll-container__light-text {
    color: #696969;
}
.polls_container__status-indicator {
    width: 6px;
    height: 6px;
    border-radius: 50px;
    margin-right: 5px;
    margin-top: 3.5px;
}
.polls_container__status-indicator.active {
    background-color: #1dc219;
}
.polls_container__status-indicator.inactive {
    background-color: #ffbb44;
}
.polls_container__status-indicator.completed {
    background-color: #2f80ed;
}
.polls_container__status-text.active {
    color: #1dc219;
}
.polls_container__status-text.inactive {
    color: #ffbb44;
}
.polls_container__status-text.completed {
    color: #2f80ed;
}
.poll-container__edit-button {
    margin: 4px 2px 0 0px;
    padding: 1em;
    width: 100%;
    cursor: pointer;
    background-color: white;
    border-bottom: 2px solid #0058fa;
    display: flex;
    align-items: center;
    justify-content: center;
}
.poll-container__delete-button {
    margin: 4px 0 0 2px;
    width: 100%;
    padding: 1em;
    cursor: pointer;
    color: #ff0000;
    background-color: white;
    border-bottom: 2px solid #ff0000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.poll-container__edit-text {
    color: #0058fa;
    font-family: Mulish;
    margin-left: 4px;
    text-align: center;
}
.poll-container__delete-text {
    color: #ff0000;
    font-family: Mulish;
    margin-left: 4px;
    text-align: center;
}

.nosearch-centered {
    justify-content: center;
}

.show-more-polls {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    font-size: 15px;
    margin-bottom: 15px;
    color: #0058fa;
}
.show-more-polls:hover {
    cursor: pointer;
}
.showmore_list_polls {
    display: flex;
    justify-content: center;
    margin-right: 40%;
}
.active_list_padding_container {
    padding-right: 0.3em;
}

.inactive_list_padding_container {
    padding-right: 1.8em;
}
