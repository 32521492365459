.asset-details__container.company-info {
    flex-direction: column;
    // height: calc(100vh - 253px);
    overflow: auto;
    flex-grow: 1;
}
.asset-details__container.margin-bottom-comInfo {
    margin-bottom: 8em;
}

.asset-details__container.company-info > .hr {
    border-bottom: 1px solid #e8e8e8;
    display: block;
    margin-bottom: 20px;
    margin-top: -10px;
}

.verify-box {
    background-color: #ffffff;
    padding: 20px 30px;
    font-family: Mulish;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #696969;
    margin-bottom: 10px;
}
.see-more {
    color: #0058fa;
}
.see-more:hover {
    cursor: pointer;
}

.asset-details__container.company-info > p {
    font-family: Mulish;
    font-size: 16px;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
}

.asset-details__container.company-info > div {
    display: grid;
    grid-template-columns: 35.33% 33.33% 33.33%;
}

.asset-details__container.company-info > div:last-child {
    grid-template-columns: 33.33% 66.67%;
}
.asset-details__container.company-info > div:first-child {
    grid-template-columns: 50% 50%;
}
.company-setup__field-container {
    margin-right: 20px;
    padding-bottom: 10px;
}
.asset-details__text-value.small {
    text-transform: lowercase;
}
.company-setup__field-container .input_field_full_wrapper {
    height: 50px;
    width: 100%;
}
.company-setup__field-container .upload_files_input_wrapper {
    height: auto;
    padding: 1em 2em;
}
.company-setup__field-container .dragandDrop_content {
    margin-top: 0;
    margin-left: 10px;
}
.company_logo {
    height: 50px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.footer-company-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.log {
    // border: 1px solid red;
    background-color: #f3f4f8;
    // min-width: 30px;
}
.contact-Info {
    display: flex;
    flex-direction: column;
    margin-top: 23px;
}
.contact-Info-Header {
    font-family: Mulish;
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px;
}
.contact-Info > p {
    display: flex;
}
.contact-Info > p > img {
    height: 16px;
    width: 16px;
    margin-right: 13px;
}
.contact-Info > p > span {
    font-size: 13px;
    color: #696969;
}
.marginBottom10 {
    margin-bottom: 10px;
}
.contact-Info-Link {
    color: #0058fa;
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 5px;
}
.animation {
    background-color: #f3f4f8;
    height: 134px;
    margin-bottom: 22px;
    margin-top: 15px;
}
.edit-button {
    margin-left: 10px;
    color: #0058fa;
    text-transform: capitalize;
    font-size: 0.7em;
    text-decoration: underline;
    cursor: pointer;
}

.error-upload-company-info{
    border: 1px solid red !important;
    background-color: #ffffff !important;

}

.input__error-text-company-info {
    // padding: 0px 0px;
    margin-top:-4px;
    font-size: 12px;
    line-height: 16px;
    // font-weight: bold;
    color: #f00;
    font-family: Mulish;
    // position: absolute;
    // bottom: -2rem;
}
