.container_border {
    border-bottom: 1px solid #f3f4f8;
}

.feedback_content_wrapper {
    padding: 17px 42px;
    background-color: #fff;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    
    // width: 500px;
}

.page_wrapper {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    padding: 20px 0px;
}

.buttons_wrapper {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.button_wrap {
    margin-left: 5px;
}

.container_feedback {
    padding-top: 25px;
    padding-bottom: 25px;
    
}

.container-comment-row{
    padding: 17px 42px 25px;
    background-color: #fff;
}
