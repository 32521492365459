html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
}

#root {
    height: 100vh;
}

h3 {
    margin: 0;
}

h1 {
    margin: 0;
    font-weight: unset;
}
h2 {
    margin: 0;
    font-weight: unset;
}

.personalHeading {
    text-align: center;
    font-size: 30px;
    font-style: bold;
    color: #4b6f90;
    padding: 40px 0px 40px 0px;
}

.dataFields {
    text-align: center;
    font-size: 15;
    font-style: bold;
    padding: 10px 10px 10px 10px;
}

.titleName {
    padding-bottom: 5px;
}

.dataWrapper {
    width: 45em;
    margin: auto;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
}

select {
    width: 19em;
}

.buttonWrapper {
    display: flex;
    justify-content: center;
    padding: 2em;
}

.logout__div {
    position: absolute;
    top: 5%;
    right: 2%;
}

.logout__btn {
    padding: 8px;
    border: 0;
    outline: none;
    background-color: #0058fb;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 95px;
    border-radius: 22.5px;
    cursor: pointer;
    box-shadow: 0px 5px 15px -11px rgba(0, 0, 0, 1);
}

@font-face {
    font-family: 'ChainsawGeometric';
    src: local('ChainsawGeometric'),
        url('./assets/chainsaw-geometric/ChainsawGeometric.ttf');
    font-display: block;
    font-style: normal;
}

@font-face {
    font-family: 'Muli-Bold';
    src: local('Mulish');
    font-style: normal;
    font-display: auto;
    font-weight: 700;
}

@font-face {
    font-family: 'Muli';
    src: local('Mulish');
    font-style: normal;
    font-display: auto;
}
