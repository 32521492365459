@import '../../sass/main.scss';

.employeesHeader-container {
    @include flex__spacebetween;
    align-items: center;
    width: 100%;
    margin-bottom: 1.91em;
    height: 3.16em;
    &__button_container {
        margin-left: 1em;
        white-space: nowrap;
    }
}

.employeesHeader_rightContent {
    // width: 70%;
}

.required_field_asterisks {
    // color: #ff7474;
    color: #eb1d2b;
    font-size: 12px;
}
.add-employee-sub-title {
    font-family: Mulish;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #929292;
    //   position: absolute;
    //   top: 142px;
}
.required_text {
    color: #929292;
    font-size: 12px;
    font-family: Mulish;
}

.custom_header_dropDown {
    width: 12vw;
    position: relative;
    margin-bottom: 0.5em;
    // z-index: 4;
}

.button_container-sample {
    // background-color: #0058fa;
    border: solid 1px #0058fa;
    padding: 10px 20px;
    height: 35px;
    display: flex;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none !important;
    font-family: Mulish;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    /* identical to box height */
    color: #0058fa;
}

a:hover {
    // color: white;
    text-decoration: none !important;
    cursor: pointer;
}
a {
    text-decoration: none !important;
}

.button_container-excel {
    // background-color: #0058fa;
    border: solid 1px #1f7244;
    padding: 10px 20px;
    height: 35px;
    display: flex;
    // text-transform: uppercase;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: Mulish;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    /* identical to box height */
    color: #1f7244;
}

.excel-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.upload_excel {
    // height:100%;
    width: 162px;
    position: absolute;
    cursor: pointer;
    outline: none !important;
    border: none;
}
