@import '../../../../sass/main.scss';

.add_policy_container {
    background-color: #fff;
    padding: 3.5rem;
    overflow: scroll;
}

.add_policy_date_wrap {
    // width: 39%;
    width: 41%;
    // margin-right: 5px;
}

.add_policy_category_wrap {
    // width: 43%;
    // margin-left: 31px;
    // width: 47.3%;
    width: calc(100% - 41% - 60px);
}

.bottom_space {
    margin-bottom: 10px;
}
.input_field_full_wrapper_addPolicy {
    width: 39%;
}
