.employee-assets__modal-container {
    min-width: 400px;
}
.asset-details__results-container {
    max-height: 40vh;
    overflow: auto;
    min-width: 41vw;
    min-height: 40vh;
}
.asset-details__search-box {
    width: 100%;
    border-radius: 2px;
    border: solid 1px #e8e8e8;
    margin-bottom: 30px;
}

.no_assets_text {
    text-align: center;
    font-family: Mulish;
    font-size: 1em;
}

.no-search-table-image {
    display: block;
    height: 200px;
    margin: auto;
    margin-bottom: 20px;
}

.show-more-employee {
    display: flex;
    justify-content: center;
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #0058fa;
}
.show-more-employee:hover {
    cursor: pointer;
}
.showmore_list_coun-employee {
    position: absolute;
    top: 50%;
    right: 45%;
}

.showmore_loader_img-employee {
    width: 6em;
    height: 6em;
}

.default_assets_onboarding {
    padding: 4em 20em;
    font-family: Mulish;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: #696969;
    text-align: center;
}

.asset_icon_container {
    margin-bottom: 1em;
    height: 150px;
}

.table_row_assets_wrapper {
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #f3f4f7;
    border-radius: 0.33em;
    padding: 1.667em 0.833em;
    height: 4.7em;
    position: relative;
}

.asset_return_text {
    color: #ff0000;
    font-size: 1em;
    font-family: Mulish;
}

.onboard_asset_modal {
    width: 50%;
}

.onboard_container_assets {
    overflow: scroll;
}
