@import '../../../../sass/main.scss';

.add_support-details__container {
    background-color: #fff;
    padding: 3.5rem 3.5rem 1.5rem 3.5rem;
    display: flex;
    flex-direction: column;
}

.support_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.add_support_text {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3em;
    line-height: 1.92em;
    color: #000000;
    margin-bottom: 20px;
}

.button_row_support {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 0;
    padding-bottom: 20px;
}

.row_input_wrap {
    width: 50%;
}

.input_field_wrapper_support {
    width: 49%;
    position: relative;
}

.margin_upport_fields {
    margin-top: 1em;
}
