.create-asset__container_tag {
    display: flex;
    flex-direction: column;
}

.create-asset__container_tag > div {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0;
}

.create-asset__container > div:first-child {
    padding-right: 8vw;
}

.input_asset_wrapper {
    width: 45%;
    position: relative;
}
.input_asset-pic_wrapper {
    display: flex;
    flex-direction: column !important;
}
.input_asset-pic_label {
    font-family: Mulish;
    font-size: 12px;
    color: #696969;
    margin-bottom: 10px;
}
.input_asset-pic_container_Wrapper {
    display: flex;
}
.input_asset-pic_container {
    width: 100px;
    height: 100px;
    margin: 11px 15px 0 0;
    padding: 7px 7px 8px 8px;
    border: dashed 2px #0059fb;
    background: rgba(0, 88, 250, 0.07);
}
input {
    color: transparent;
    padding: 7px 8px 7px 8px;
    border: transparent;
}
.pic_upload {
    // background: rgba(0, 88, 250, 0.07);
    width: 87px;
    height: 93px;
    position: relative;
    bottom: 21px;
    cursor: pointer;
}
input::-webkit-file-upload-button {
    visibility: hidden;
}
.input_asset_pic_container_close {
    position: relative;
    bottom: 15px;
    left: 82px;
}
.input_asset_pic_container_preview {
    width: 85px;
    height: 85px;
    position: relative;
    bottom: 18px;
    right: 2px;
}
.asset-pic_loader_container {
    position: relative;
    bottom: 10px;
    right: 10px;
}
.asset-pic_load_container {
    position: relative;
    bottom: 18px;
    right: 2px;
}
.asset-pic_loader {
    width: 100px;
    height: 100px;
}
.asset_pic_add {
    position: relative;
    top: 43%;
    left: 43%;
}
.asset_pic_add_icon {
    width: 14px;
    height: 14px;
}
