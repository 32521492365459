@import '../../../../sass/main.scss';

.offer-packet-main {
    padding-bottom: 2.083em;
}
.bottom_padding_container {
    padding-bottom: 2em;
}

.offer-packet-modal-container {
    padding: 45px 49px;
    background-color: #ffffff;
    height: 100%;
}
.offer_packet_model_wrapper {
    // overflow: auto;
}
.container {
    padding-bottom: 2.083em;
}
.contact-info-modal-header {
    @include flex__spacebetween;
    width: 100%;
    margin-bottom: 1em;
}

.display_docs {
    width: 100%;
}

.date_field_container {
    width: 48%;
}

.date-field-wrapper {
    width: 100%;
}

.date-field-wrapper .date-picker__input-container {
    width: 100%;
}

// .date-field-wrapper .date-picker__input-container {
//     flex: 1;
// }

.offer-packet-modal-header-content {
    font-size: 1.5em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.required-text-container {
    font-size: 1em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ff7474;
    margin-bottom: 1.66em;
}

.offer-packet-modal-cancel {
    cursor: pointer;
}

.input_fields_container {
    @include flex__spacebetween;
    width: 100%;
    margin-bottom: 0.5em;
}

.input_fields_container_last {
    @include flex__spacebetween;
    width: 100%;
}

.input_fields_container_upload_offer {
    margin-top: 2em;
    flex-direction: column;
    width: 100%;
}

.input_fields_container_contact_info {
    @include flex__spacebetween;
    width: 100%;
    margin-bottom: 1.5em;
}

.input_field_wrapper {
    width: 48%;
    position: relative;
}

.input_field_wrapper_contact_info {
    width: 100%;
    position: relative;
}

.offer_letter_container {
    background-color: #fff;
    margin: 1em 0;
    padding: 2.083em 4.16em;
}

.preview_container {
    background-color: #fff;
    padding: 28px 41px;
}

.preview_field_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: solid 1px #f3f4f8;
}

.docs_field_container {
    display: flex;
    flex-direction: column;
}

.date-picker__calendar-icon {
    height: 20px;
}

.drop_down_container {
    width: 100%;
    border: solid 1px #ccc;
    padding: 10px 5px;
}

.red_border {
    border: solid 1px #f00;
}

.drop_down_field {
    padding: 0.4em;
    cursor: pointer;
}

.drop_down_field:hover {
    background-color: #ccc;
}

.input_field_box {
    width: 100%;
    height: 45px;
    border-radius: 3px;
    border: solid 1px #e8e8e8;
    background-color: #ffffff;
    padding: 0 10px;
    font-size: 14px;
    font-weight: bold;
    color: #3a4354;
    font-family: Mulish;
}

.input_field_box_red_border {
    width: 100%;
    height: 45px;
    border-radius: 3px;
    border: solid 1px #f00;
    background-color: #ffffff;
    padding: 0 10px;
    font-size: 14px;
    font-weight: bold;
    color: #3a4354;
    font-family: Mulish;
}

.input_field_box_contact_info {
    width: 100%;
    height: 3.083em;
    opacity: 0.9;
    border-radius: 0.2em;
    // box-shadow: 0 4px 4px 0 rgba(25, 27, 34, 0.1);
    border: solid 1px #d7e3f1;
    background-color: #ffffff;
    padding: 0.833em;
    text-align: left;
}

.input_field_box_comment_info {
    width: 100%;
    height: 7em;
    opacity: 0.9;
    border-radius: 0.2em;
    border: solid 1px #c4c4c4;
    background-color: #ffffff;
    padding: 0.833em;
    text-align: left;
    margin-top: 1.5em;
}

.offer_details_preview_section {
    margin-top: 19px;
}

.docs_preview_heading {
    font-size: 16px;
    font-family: Mulish;
    font-weight: 500;
    color: #000000;
    margin-top: 1em;
    margin-bottom: 12px;
}

.salary_input_field_box {
    width: 100%;
    border: none;
}
.annual_salary_container {
    width: 15%;
    font-family: Mulish;
    font-size: 1.16em;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    color: #666666;
}

.salary_input_field_box_wrapper_red_border {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    opacity: 0.9;
    border-radius: 0.25em;
    border: solid 1px #f00;
    background-color: #ffffff;
    padding: 0.96875em;
    padding-right: 0;
    text-align: left;
}

.salary_input_field_box_wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    opacity: 0.9;
    border-radius: 0.25em;
    border: solid 1px #e8e8e8;
    background-color: #ffffff;
    padding: 0.96875em;
    padding-right: 0;
    text-align: left;
}

.input_field_header {
    margin-bottom: 5px;
}

.salary_input_field_box::placeholder {
    font-family: Mulish;
    font-size: 14px;
    opacity: 0.5;
    font-weight: normal;
    color: #3a4354;
}

.input_field_box::placeholder {
    font-family: Mulish;
    font-size: 14px;
    opacity: 0.5;
    font-weight: normal;
    color: #3a4354;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red;
}

.gender_container_wrapper {
    width: 100%;
    height: 45px;
}

.offer_loader_container {
    height: 25px;
    width: 26px;
}

.gender_select_option_container {
    width: 1.42em;
    height: 1.42em;
    border-radius: 50%;
    border: solid 1px #dbe6f2;
    margin-right: 0.42em;
    cursor: pointer;
}

.gender_selected_option {
    border: solid 1px #0058fa;
}
.gender_select_option_dot {
    width: 0.583em;
    height: 0.583em;
    border-radius: 50%;
    background-color: #0058fa;
}
.gender_wrapper {
    margin-right: 4em;
}

// .input_field_wrapper .react-datepicker-wrapper {
//     width: 100%;
// }

// .image_date_container {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
// }

// .input_field_wrapper .react-datepicker__input-container input {
//     width: 100%;
//     height: 45px;
//     opacity: 0.9;
//     border-radius: 0.25em;
//     border: solid 1px #e8e8e8;
//     background-color: #ffffff;
//     margin: 0;
//     font-size: 14px;
//     padding: 0.96875em;
// }

.input_field_full_wrapper {
    width: 48%;
    position: relative;
}

.upload_files_input_wrapper {
    width: 100%;
    height: 8em;
    border: dashed 1px #99b7eb;
    background-color: #eef4ff;
    padding: 2em;
    position: relative;
    cursor: pointer;
}
.upload_files_input {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.personal_info_container {
    border-bottom: solid 1px #f3f4f8;
    margin-bottom: 2em;
    padding-bottom: 1em;
}

.offer_details_container {
    border-bottom: solid 1px #f3f4f8;
    margin-bottom: 2em;
    margin-top: 2em;
    padding-bottom: 1em;
}

.error_text {
    padding: 0px;
    font-size: 12px;
    line-height: 16px;
    // font-weight: bold;
    color: #f00;
    font-family: Mulish;
}

.buttons_container {
    display: flex;
    flex-direction: row;
}

.buttons_container > div {
    margin-left: 5px;
}

.buttons_wrapper {
    width: 100%;
    display: flex;
    margin-top: 1em;
    flex-direction: row;
    // justify-content: space-between;
}

.offer_packet_submit_button {
    padding: 1em;
    background-color: #0058fa;
    margin-bottom: 2.5em;
    margin-right: 1em;
    cursor: pointer;
}

.offer_packet_edit_button {
    padding: 1em;
    background-color: #666666;
    margin-bottom: 2.5em;
    margin-right: 1em;
    cursor: pointer;
}

.offer_packet_preview_button {
    padding: 1em;
    background-color: #0058fa;
    margin-bottom: 2.5em;
    cursor: pointer;
}

.date_Img_Container {
    position: absolute;
    width: 1em;
    cursor: pointer;
    height: 1em;
    right: 0.83em;
    pointer-events: none;
    top: 3.6em;
    cursor: pointer;
}

.date-img-content {
    width: 100%;
    height: 100%;
}

.currency_icon {
    width: 1.16em;
    height: 1.16em;
}
.dragandDrop_content {
    font-family: Mulish;
    font-size: 1.16em;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0058fa;
    margin-top: 10px;
}
.file_loader_container {
    width: 3em;
    height: 3em;
}

.arrow_styles {
    transform: rotate(180deg);
}

.uploaded_file_container {
    width: 100%;
    margin-top: 0.583em;
    background-color: rgba(0, 88, 250, 0.06);
    padding: 1em;
}

.half_width {
    width: 48%;
}

.edit_container {
    background-color: #fff;
    padding: 28px 41px;
}

.uploaded_file_preview {
    width: 48%;
    margin-top: 0.3em;
    background-color: rgba(0, 88, 250, 0.06);
    border: 1px solid #0058fa;
    padding: 1em;
}

.cancelUpload_icon {
    width: 0.833em;
    height: 0.833em;
    cursor: pointer;
}

.uploaded_file_link {
    margin-left: 0.66em;
    cursor: pointer;
}

.required_field {
    margin-bottom: 1.66em;
}
.required_field_asterisks {
    // color: #ff7474;
    color: #eb1d2b;
    font-size: 12px;
}
.disabled_button {
    // pointer-events: none;
    cursor: not-allowed;
}

.dropdown_arrow_container {
    width: 10%;
}
.dropDownArrow_rotate {
    transform: rotate(180deg);
}

.noAttachment {
    padding-left: 7px;
}
