.asset-details__container {
    background-color: #fff;
    padding: 2.5rem;
    padding-bottom: 0;
    display: flex;
}
.asset-details__container > div {
    flex: 1;
    text-transform: capitalize;
}
.asset-details__text-key {
    font-family: Mulish;
    font-size: 14px;
    color: #696969;
    margin-bottom: 8px;
}
.poll-actions-wrapper {
    display: flex;
    justify-content: center;
}
.asset-details__text-value {
    font-family: Mulish;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    color: #3a4354;
    margin-bottom: 40px;
    line-height: 1.5;
}
.asset-details__modal-container {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.asset-details__modal-title {
    font-family: Mulish;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    text-transform: capitalize;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.asset-details__modal-text {
    font-family: Mulish;
    font-size: 1rem;
    margin-bottom: 20px;
}
.asset-details__modal-button-container {
    display: flex;
    justify-content: flex-end;
}
.asset-details__modal-button-container > div {
    margin-left: 10px;
}
.asset-details__modal-search-bar {
    width: 30vw;
    height: 37px;
    border-radius: 2px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 30px;
}
.asset-details__modal-search-bar:focus-within {
    border-bottom: 1px solid #0058fa;
}
.asset-details__modal-input {
    background-color: transparent;
    border: none;
    padding-left: 10px;
    font-family: Mulish;
    font-size: 16px;
    color: #444444;
}
.asset-details__modal-button-container {
    display: flex;
    margin-top: 30px;
}
