@import '../../sass/main.scss';

.profile_details_container_education {
    margin-top: 1em;
    background-color: #ffffff;
    padding: 2em;
}

.employee_profile-desc-photo {
    @include flex-row__center;
}

.employee_details_name_container {
    margin-left: 1.66em;
}

.opacity_not_allowed {
    opacity: 0.2;
    cursor: not-allowed;
}

// .detail_container_wrapper_education {
//     width: 50%;
//     // padding-left: 1.2em;
//     margin-top: 1.5em;
// }
.column {
    margin: 0;
    margin-bottom: 0.83em;
}
.width_profile_details {
    width: 9em;
}

.detail_container_wrapper_header_title {
    font-size: 1.6em;
    font-weight: bold;
    font-family: Mulish;
    color: black;
}

.skills-content {
    border-radius: 33.5px;
    padding: 4px 14px 5px 14px;
    border: 1px solid $color-secondary-blue;
    color: $color-secondary-blue;
    margin: 0 10px 10px 0;
    font-weight: 600;
    text-transform: capitalize;
}

.flex-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0.5em 1em;
}

.skills-container {
    display: flex;
    flex-wrap: wrap;
}

.skills-container-whole {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 0 0;
}

.detail_container_wrapper_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.skill_bar_container {
    display: flex;
    flex-direction: 'row';
    justify-content: space-between;
    align-items: flex-end;
    margin-left: 10px;
}

.skill_bar {
    height: 0.5em;
    width: 0.3em;
    background-color: #0058fa;
    opacity: 0.3;
}

.skill_opaque {
    opacity: 1;
}

.skill_second_bar {
    height: 0.9em;
    margin: 0 2px 0 2px;
}

.skill_third_bar {
    height: 1.2em;
    margin-left: 1;
}
