.policy_details_container {
    background-color: #fff;
    padding: 12px 42px 42px 42px;
}

.policy_details_first_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date_category_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%; // 40%;
}
.rowbar {
    margin: 20px 0;
    width: 100%;
    height: 1px;
    background-color: #f3f4f8;
}
.classContentStyle {
    font-size: 16px;
    font-family: Mulish;
    color: #404b5b;
}
.common_row {
    padding: 20px 0;
    border-bottom: 1px solid #f3f4f8;
}

.uploaded_file_preview {
    width: 48%;
    margin-top: 0.3em;
    background-color: rgba(0, 88, 250, 0.06);
    border: 1px solid #0058fa;
    padding: 1em;
}

.upload_files_input_wrapper {
    width: 100%;
    height: 8em;
    border: dashed 1px #99b7eb;
    background-color: #eef4ff;
    padding: 2em;
    position: relative;
    cursor: pointer;
}

.dragandDrop_content {
    font-family: Mulish;
    font-size: 1.16em;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0058fa;
    margin-top: 10px;
}

.upload_files_input {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.uploaded_file_preview {
    width: 48%;
    margin-top: 0.3em;
    background-color: rgba(0, 88, 250, 0.06);
    border: 1px solid #0058fa;
    padding: 1em;
}

.cancelUpload_icon {
    width: 0.833em;
    height: 0.833em;
    cursor: pointer;
}
.classPolicyFontLarger {
    font-family: Mulish;
    font-weight: bold;
    font-size: 14px;
    color: #404b5b;
    padding-left: 7px;
    padding-top: 10px;
    word-wrap: break-word;
}
