@import '../../sass/main.scss';

.tableWrap {
    // min-height: 70vh;
    max-height: 76vh;
    overflow: scroll;
    padding-bottom: 10px;
}


.tableWrapper{
    max-height: 76vh;
    overflow: scroll;
    padding-bottom: 10px;
    min-height: 60vh;

}

.actionTableWrap {
    // height: calc(100vh - 153px);
    // overflow: scroll;
    padding-bottom: 9em;
}

.assets_class_table {
    // height: calc(100vh - 30.5vh);
    // height: calc(100% - 153px);
    max-height: 70vh;
    overflow: scroll;
    padding-bottom: 10px;
}

.top_box {
    // bottom: 65%;
    top:-847% !important;
}

.table_show_count {
    margin-bottom: 7em;
    // height: calc(100vh - 240px);
}

.full_height {
    height: calc(100vh - (5em + 1em + 2em + 1em + 3.16em + 1.91em));
}
table {
    position: relative;
    z-index: 0;
}

thead tr th {
    position: relative;
    top: 0;
}

.table_container {
    width: 100%;
}
.table_header_content {
    text-align: left;
    padding-bottom: 1.083em;
    padding-top: 1.083em;
    background-color: #fff;
    z-index: 3;
    min-width: 12.725rem;
    border-bottom: 1px solid #f3f4f7;
    height: 5.33em;
    vertical-align: middle;
}
.table_header_content_role {
    text-align: left;
    padding-bottom: 1.083em;
    padding-top: 1.083em;
    background-color: #fff;
    z-index: 3;
    min-width: 12.725rem;
    border-bottom: 1px solid #f3f4f7;
    height: 5.33em;
    vertical-align: middle;
}

.table_header_content:first-child {
    padding-left: 2.5rem;
}

.table_header_content:last-child {
    padding-right: 2.5rem;
}
.checkbox_wrapper {
    max-width: fit-content;
}
.table_wrapper {
    width: 100%;
    margin-top: 2.91em;
    margin-bottom: 1.25em;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 2px 2px 2px rgba(172, 181, 189, 0.2);
}
.add-employee-sub-title {
    font-family: Mulish;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #929292;
    //   position: absolute;
    //   top: 142px;
}

.min_width {
    min-width: 8em;
}

.employees.setup_emp_table {
    vertical-align: middle;
    position: unset;
    max-width: 18em;
    font-family: Mulish;
    font-size: 1.1667em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #404b5b;
}

.employees.setup_emp_table:first-child {
    padding-left: 2.5rem;
}

.employees.setup_emp_table .dropdown-wrapper {
    min-width: 200px;
}
.employees.setup_emp_table:nth-child(5) {
    max-width: 12em;
    padding-right: 2.5rem;
    // padding-right: 0px;
}
.employees.setup_emp_table:last-child {
    min-width: 1em;
    width: auto;
}
.employees.setup_emp_table:nth-child(3) {
    min-width: 1em;
    width: auto;
}

.table_row_content {
    vertical-align: middle;
    position: unset;
    max-width: 18em;
    font-family: Mulish;
    font-size: 1.1667em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #404b5b;
    // text-transform: capitalize;
}
.table_row_content .dropdown-wrapper {
    min-width: 200px;
}
.table_row_content:last-child {
    position: relative;
    padding-right: 25px;
}
// .table_row_wrapper:hover ~ .name_container_text >  {
//     color: #0058fa;
// }
.table_row_wrapper:hover {
    background-color: #f6faff;
    // box-shadow: 0 3px 3px 3px rgba(0, 88, 250, 0.1);
}

.header_text:hover {
    color: #000;
}
// hover is not present in appdev so removed for header else uncommet

.bluish:first-child {
    font-family: 'Mulish';
    color: #0058fa;
}
.bluishAssigned:first-child {
    color: #0058fa;
}
.bluishAssigned:nth-child(2) {
    color: #0058fa;
}

.table_row_wrapper {
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #f3f4f7;
    border-radius: 0.33em;
    // padding: 1.667em 0.833em;
    height: 4.7em;
    position: relative;
    cursor: pointer;
}

.setup-employees-listing .table_row_wrapper.blue_table {
    background-color: #f6faff;
}

.show_cursor_default {
    cursor: default;
}

.table_row_wrapper.selected {
    background-color: #f6faff;
}
.table_row_content:first-child {
    padding-left: 2.5rem;
}

table th::before {
    position: absolute;
    right: 0.41em;
    top: 18%;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
table th.sort-asc::before {
    border-bottom: 5px solid #a1a1a1;
}
table th.sort-desc::before {
    border-top: 5px solid #a1a1a1;
}

.table_action_container {
    position: absolute;
    width: 16.667em;
    height: 16.667em;
    right: 0;
    top: 0;
    border: solid 0 #000000;
    background-color: #ffffff;
    z-index: 1;
}
.action_icon_wrapper {
    padding: 0.75em 0;
    cursor: pointer;
}

.action_text {
    font-family: Mulish;
    font-size: 1.16em;
    color: #444444;
    // padding-left: 0.83em;
}

.action_icon_wrapper .action_text {
    font-family: Mulish;
    font-size: 1.16em;
    color: #0058fa;
    white-space: nowrap;
}
.action-text-new {
    font-family: Mulish;
    font-size: 15px;
    color: #0058fa;
}

.action_icon_wrapper:hover .action_text {
    text-decoration: underline;
}

.header_text {
    font-family: Mulish;
    font-size: 0.833em;
    color: #929292;
    line-height: 1.25em;
    text-transform: uppercase;
}

.selected_color {
    color: #000;
}

.table_sort_display_none {
    display: none;
}

.table_sort_icon {
    margin-left: 0.8em;
}

.no_data_to_show {
    width: 100%;
    text-align: center;
    font-family: Mulish;
    font-size: 1.5em;
    color: #444444;
    padding-left: 0.83em;
}
.details_expenses {
    text-align: left;
    padding-bottom: 1.083em;
    padding-top: 1.083em;
    background-color: #fff;
    z-index: 3;
    min-width: 5em;
    border-bottom: 1px solid #f3f4f7;
    height: 5.33em;
    vertical-align: middle;
}
.details_expenses:first-child {
    // text-align: center;
    padding-left: 50px;
}

.padding_right_content {
    padding-right: 1.5rem;
}

.Table-header_TH {
    font-family: Mulish;
    font-size: 0.833em;
    color: #929292;
    line-height: 1.25em;
    text-transform: uppercase;
}
.Table-header_TH-onClick {
    font-family: Mulish;
    font-size: 0.833em;
    color: black;
    line-height: 1.25em;
    text-transform: uppercase;
}

.Table-header_TH:hover {
    cursor: pointer;
    color: black;
}
.Table-header_TH-onClick:hover {
    cursor: pointer;
}

.table_sort_icon-assets {
    margin-left: 0.8em;
}
.table_sort_icon-assets :hover {
    cursor: pointer;
}

.add-employee-container {
    padding:20px;
    display:flex;
    flex-direction: column;
    // width: 1024px;
//   height: 507px;
}

.search-container-employees{
    width: 300px !important;
    background-color: #ffffff;
    padding: 10.5px;
    height: 37px;
    cursor: default;
}

.input__container-profile-employee-only {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5em;
    position: relative;
    height:90px;
}

.add-employee-modal-header{
  font-family: 'Mulish';
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.employee-modal-cancel {
    position: absolute;
    right:10px;
    top:10px;
    cursor: pointer;
    // margin: 10px,0,0,10px;
    // width:20px;
}

.add-employee-input-container {
    padding-top: 40px;
}

.add-employee-input-wrapper{
    display: flex;
    flex-direction: row;
    padding-bottom:10px;
}

.input_field_wrapper-employee-modal {
    width: 30rem;
    position: relative;
    margin-right:20px;
}

.input_field_wrapper-employee-modal-ID {
    width: 30rem;
    position: relative;
    margin-right:20px;
    height:90px;
}

.add-employee-modal-button-container{
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
}

.employee-add-modal-button2{
    margin-right:20px;
}
.employee-add-modal-button1{
    margin-right:10px;
}


