$color-black: #000;
$color-white: #fff;
$color-grey-light: #ccc;
$color-grey-dark: #8888;
$color-grey-darker: #696969;

$color-primary-blue: #0058fb;
$color-primary-blue-transparent: #0058fb0d;
$color-secondary-blue: #0463f7;
$color-tertiary-blue: #0100a6;
$color-new-blue: #0058fa;

$color-primary-red: #ff0013;
$color-secondary-red: #ef4848;
$color-tertiary-red: #f04d4d;
