@import '../../../../components/RadioInput/radioInput.scss';

.create-announcement__container {
    background-color: #ffffff;
    padding: 40px;
}
.create-announcement__date-time-container {
    display: flex;
}
.create-announcement__button-container {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
}
.create-announcement__button-container > div {
    margin-right: 10px;
}
.create-announcement__button-container > div:last-child {
    margin-right: 0px;
}
.create-announcement__warning {
    font-family: Mulish;
    font-size: 12px;
    color: #929292;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.scheduleLater {
    color: #0058fa;
    font-size: 14px;
    cursor: pointer;
    margin-top: 13px;
}
.description-limiter {
    color: #929292;
    font-size: 11px;
    text-align: right;
}
