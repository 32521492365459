@import '../../../../sass/main.scss';

.events-card-conatiner {
    width: 100%;
    padding: 25px 30px;
    margin-top: 10px;
    background-color: #fff;
}

.event-date-container {
    width: 80px;
    height: 95px;
    background-color: #f3f4f8;
    margin-right: 30px;
}

.events-date-content {
    font-family: Mulish;
    font-size: 28px;
    font-weight: bold;
    color: #3a4354;
}

.events-month-content {
    font-family: Mulish;
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #929292;
    text-transform: uppercase;
    margin-top: 2px;
}

.event-details-container {
    width: calc(100% - 110px);
    height: 95px;
}
.event-title-container {
    width: 70%;
}
.event-photos-container {
    // width: 30%;
}

.event-draft-text {
    font-family: Mulish;
    font-size: 12px;
    font-weight: bold;
    color: #929292;
    margin-left: 10px;
}

.new-text-event {
    font-family: Mulish;
    font-size: 12px;
    font-weight: bold;
    color: #0058fa;
    margin-left: 10px;
    width: max-content;
}

.event-title-content {
    font-family: Mulish;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: #3a4354;
    white-space: nowrap;
}

.event-description {
    width: 70%;
    font-family: Mulish;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3a4354;
    margin: 5px 0;
    margin-left: 11px;
}

.event-description-ellipsis {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 36px;
    overflow: hidden;
}

.event-location-container > div {
    font-family: Mulish;
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #696969;
    width: 30em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-location-container > div:first-child {
    margin-right: 30px;
    max-width: 14em;
}

.events-added-by {
    font-family: Mulish;
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #696969;
    text-align: right;
    margin-bottom: 8px;
}

.event-partcipants-desc {
    @include flex-row__center;
    position: relative;
}

.events-rounded-icon-photo {
    display: block;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    border: 1px solid $color-grey-light;
    object-fit: cover;
    background-color: #fff;
}

.event_no_pic_profile {
    width: 25px;
    height: 25px;
    border: solid 1px #c4c4c4;
    background-color: #ffffff;
    border-radius: 50%;
    @include flex-row__center;
}

.event-partcipants-container {
    transform: translateX(50px);
}

.showMore-events-content {
    font-family: Mulish;
    font-size: 14px;
    font-weight: bold;
    color: #444444;
    margin-left: 15px;
}

.event-card-participants {
    margin-bottom: 8px;
}

.event_everyone {
    font-family: Mulish;
    font-size: 13px;
    font-weight: bold;
    color: #444444;
    text-align: right;
}
