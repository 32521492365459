// @import '../../sass/main.scss';
@import '../../../sass/main.scss';
@import '../../../sharedComponents/SkillsList/SkillsList.scss';

.last_cell_expenses_table {
    display: flex;
    // justify-content: space-between;
    // width: 72%;
    margin-left: 6px;
    cursor: pointer;
}
.last_cell_expenses_approve {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0058fa;
    color: #ffffff;
    padding: 0px 20px;
    margin-right: 15px;
    height: 35px;
}

.last_cell_expenses_reject {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #666666;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
}
.ab {
    // margin: 0px 20px;
    padding: 0px 50px;
}
// .last_cell_expenses_text {
//     // border: 1px solid black;
// }

.expenses-listing__table td:first-child {
    padding-left: 1.9em;
}
.expense_list_inDetails {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    justify-content: space-between;
    //margin-bottom: 20px;
    padding: 3rem;
}
.list_box_margin_bottom {
    padding-bottom: 20px;
}
.list_box_margin {
    padding: 20px 0px;
}
.list_box_margin_top {
    padding-top: 20px;
}
.listName_padding-left {
    margin-left: 50px;
}
.listName_padding-right {
    margin-right: 50px;
}
.border_last_list {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: #f3f4f8;
}
.expense_list_inDetails2 {
    background-color: #ffffff;
    padding-left: 60px;
}
.expense_upper_list {
    display: flex;
    justify-content: space-between;
}
.expense_list_inDetails_items {
    width: 50%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
}
.expense_list_inDetails_comments {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.expense_list_inDetails_items_employee {
    display: flex;
    justify-content: space-between;
}
.size_24 {
    font-size: 24px;
    font-family: 'Mulish';
}
.size-12 {
    font-size: 12px;
    font-family: 'Mulish';
    color: #696969;
}
.size-20 {
    font-size: 16px;
    color: #3a4354;
    font-weight: bold;
    font-family: 'Mulish';
}
.size-14 {
    font-size: 14px;
    font-family: 'Mulish';
    color: #404b5b;
}
.size-16 {
    font-size: 16px;
    font-family: 'Mulish';
    color: #404b5b;
}
.header-padding {
    padding-bottom: 15px;
}
.padding-bottom-item {
    padding-bottom: 15px;
}
.min-width-employee {
    min-width: 250px;
}
.size-16-2 {
    font-size: 16px;
    font-family: 'Mulish';
    color: #3a4354;
}
.expense_dot {
    width: 7px;
    height: 7px;
    margin-right: 7px;
    border-radius: 50%;
    line-height: 20px;
    background-color: #ffbb44;
}
.approved.expense_dot {
    background-color: #1cc219;
}
.rejected.expense_dot {
    background-color: #eb1d2b;
}
.height-auto {
    height: auto;
}
.statusAndDot {
    display: flex;
    align-items: center;
}
.pending_status,
.approved_status,
.rejected_status {
    // margin-left: 5px;
    line-height: 20px;
    font-size: 16px;
    font-family: 'Mulish';
    text-transform: capitalize;
}
.pending.status_table,
.approved.status_table,
.rejected.status_table {
    margin-left: 5px;
    line-height: 20px;
    font-size: 14px;
    font-family: 'Mulish';
    text-transform: capitalize;
}
.pending_status {
    color: #ffbb44;
}
.approved_status {
    color: #1cc219;
}
.rejected_status {
    color: #eb1d2b;
}
.pending.status_table {
    color: #ffbb44;
}
.approved.status_table {
    color: #1cc219;
}
.rejected.status_table {
    color: #eb1d2b;
}
.first-title {
    // margin: 40px 0px 15px 0px;
    // display: flex;
    // margin-bottom: 5px;
    font-size: 22px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 500;
    line-height: 1.95em;
    color: #000000;
}
// .first-title-container {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     width: 100%;
//     // margin-bottom: 1.91em;
//     height: 3.16em;
// }
.second-title {
    // margin-bottom: 5px; //12px;
    margin-top: 25px; //5px;
    font-size: 22px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 500;
    line-height: 1.92em;
    color: #000000;
}
.align_self {
    align-self: flex-end;
}
.three-items {
    border: 1px solid black;
}

.toast_first_row {
    display: flex;
    flex-direction: row;
}

.toast_cross_icon {
    padding-right: 14px;
    cursor: pointer;
}

.bg-forApprovedExpense {
    background-color: #e1f3e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100%);
    // padding: 0.75em 1.667em;
    padding: 8px 0px;
    margin-bottom: 1.5em;
}
.bg-forRejectedExpense {
    background: #fbbebe;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100%);
    // padding: 0.75em 1.667em;
    padding: 8px 0px;
    margin-bottom: 1.5em;
}
.bg-forRejectedExpense_color {
    color: #ff0000;
    font-size: 14px;
}
.bg-forApprovedExpense_color {
    color: #269024;
    font-size: 14px;
}
.margin-12 {
    margin-left: 12px;
    margin-right: 2px;
}
.image_exp {
    height: 9px;
    width: 9px;
    padding-left: 14px;
}
.tick_expenses {
    height: 15px;
    width: 28px;
    padding-left: 14px;
}
.expenses-details__table td:first-child {
    padding-left: 50px;
}
.expenses-details__table tr {
    cursor: default;
}
.filterContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    // max-height: 50px;
}
.monthDropdown {
    // z-index: 4;
    width: 300px;
    // margin-top: -3px;
}
.exportBtn {
    width: 125px;
}
.expenses_filter {
    padding: 0 5px;
}
.expenses-search-box {
    width: 200px;
}
.expense-rounded-icon-photo-details {
    display: block;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: 1px solid $color-grey-light;
    object-fit: cover;
}
.expense-name-details {
    font-family: 'Mulish';
    font-size: 16px;
    font-weight: bold;
    color: #0058fa;
    padding-left: 10px;
}

.approve_modal_reason {
    width: 30vw;
    // padding: 1rem;
    padding: 15px;
}

.approve_reason_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.approve_reason_header_text {
    font-family: 'Mulish';
    font-size: 20px;
    font-weight: bold;
    color: #000000;
}

.approve_reason_question {
    font-family: 'Mulish';
    font-size: 16px;
    color: #3a4354;
    // padding-top: 10px;
    padding-top: 5px;
    padding-bottom: 20px;
}

.button_row_reason_modal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -3.5px;
}

.export_heading_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.export_class_header {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1.91em;
    height: 3.16em;
    &__button_container {
        margin-left: 1em;
        white-space: nowrap;
    }
}

.capitalize_letters {
    // text-transform: capitalize;
    padding: 0.7em 0.5em 0.7em 0;
}

.dropdown-wrapper_expense {
    width: 100%;
    position: relative;
}

.export_dropbox_height {
    height: 35px;
}

.modal_container--expenses {
    height: 50vh;
    position: relative;
    img {
        max-height: 50vh;
    }
    span {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        margin-top: 10px;
        font-weight: bold;
        cursor: pointer;
    }
}
