@import '../../../sass/main.scss';
@import '../../Events/components/EventCard/EventCard.scss';

.dashboard-container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.name-container {
    font-family: Mulish;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: #444;
}
.homeDropdownContainer {
    width: 172px;
}
.homeDropdownContainer .dropDown_container.dropDown_container-open {
    width: 172px;
    right: 3.7em;
}
.day-container {
    font-family: Mulish;
    font-size: 13px;
    text-align: left;
    color: #666;
    margin-top: 5px;
}

.goals-event-container {
    display: flex;
    flex-direction: row;
}

.goals-accomplishment-container {
    width: 66.25%;
    // height: 600px;
    // display:flex;
    // padding: 20px 30px;
    margin-top: 15px;
    // margin-bottom: 30px;
    overflow-y: scroll;
    box-shadow: 2px 2px 2px 0 rgba(172, 181, 189, 0.15);
    // overflow-x: hidden;
}

.expense-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
}
.goals-container {
    height: 300px;
    box-shadow: 2px 2px 2px 0 rgba(172, 181, 189, 0.15);
    background-color: #fff;
    margin-bottom: 15px;
    padding: 16px 21px 50px 20px;
}
.accomplishment-container {
    height: 305px;
    box-shadow: 2px 2px 2px 0 rgba(172, 181, 189, 0.15);
    background-color: #fff;
    padding: 9.2px 60px 16.2px 18px;
    margin-bottom: 15px;
}
.pending {
    font-family: Mulish;
    font-size: 18px;
    text-align: left;
    color: var(--black-two);
    //   margin-left:30px;
}

.request {
    font-family: Mulish;
    font-size: 14px;
    text-align: right;
    color: #0059fb;
    align-items: center;
    display: flex;
    //   margin-right:30px;
}

.request:hover {
    cursor: pointer;
}
.event-icon:hover {
    cursor: pointer;
}
.pendingWrapper {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pendingImageContianer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.pendingImage {
    width: 173px;
    height: 150px;
    object-fit: contain;
}
.pendingtitle {
    font-family: Mulish;
    font-size: 18px;
    font-weight: bold;
    color: #444;
    text-align: left;
    margin: 20px 0 10px 0;
}
.pendingsub {
    font-family: Mulish;
    font-size: 12px;
    color: #666;
    text-align: left;
}
.upcomingWrapper {
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dashboard-container {
    overflow: scroll;
    overflow-x: hidden;
}
.blankEvent-container {
    height: 210px;
    box-shadow: 2px 2px 2px 0 rgba(172, 181, 189, 0.15);
    background-color: #fff;
    margin: 10px 0 15px 15px;
    padding: 25px 30px;
}
.emptyTileWrapper img {
    width: 70px !important;
    object-fit: contain;
}
.blankEvent-text {
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: Mulish;
    color: #000000;
    text-align: center;
}
.event-container {
    // width: 150%;
    height: 210px;
    box-shadow: 2px 2px 2px 0 rgba(172, 181, 189, 0.15);
    background-color: #fff;
    margin: 10px 0 15px 15px;
    padding: 25px 30px;
    cursor: pointer;
}

.upcoming-wrapper {
    font-family: Mulish;
    font-size: 18px;
    text-align: left;
    color: var(--black-two);
    margin-bottom: 25px;
    align-items: center;
    display: flex;
}

.upcoming-wrapper:hover {
    cursor: pointer;
}

.event-content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.event-title {
    font-family: Mulish;
    font-size: 20px;
    text-align: left;
    color: var(--black-two);
    margin-bottom: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    height: 1.1em;
}

.event-time {
    font-family: Mulish;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-two);

    overflow: hidden;
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.event-date {
    color: #888;
}

.event-date-wrap {
    color: #888;
}

.event-content-child-wrapper {
    margin-left: 10px;
    min-width: 0;
}

.image-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.event_no_profile {
    width: 30px;
    height: 30px;
    border: solid 1px #c4c4c4;
    // background-color: #ffffff;
    background-color: #e4f1ff;
    border-radius: 50%;
    @include flex-row__center;
}

.events_all_participants {
    font-family: Mulish;
    font-size: 14px;
    font-weight: bold;
    color: #444444;
    padding: 10px;
}

.no_profile_text {
    font-family: Mulish;
    font-size: 1em;
    line-height: normal;
    letter-spacing: normal;
    color: #0059fb;
    text-align: center;
    text-transform: capitalize;
}

.holiday-container {
    // width:150%;
    height: 393px;
    box-shadow: 2px 2px 2px 0 rgba(172, 181, 189, 0.15);
    background-color: #fff;
    margin: 15px 0 15px 15px;
    padding: 25px 30px;
}

.event-holiday-wrapper {
    width: 33.5%;
    overflow: hidden;
    margin-top: 6px;
}

.tile-container {
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px #e8e8e8;
}

.homePage_dropbox {
    padding: 0 2px;
    width: 172px;
}

.homePage_dropbox_height {
    height: 35px;
}

.chevron-right {
    height: 15px;
    width: 15px !important;
    margin-left: 5px;
}

.event-holiday-wrapper .carousel .control-dots {
    bottom: 10px;
}

.event-holiday-wrapper .carousel .control-dots .dot {
    background: #0059fb;
}

.carousel-root {
    height: 221px;
}

.event-holiday-wrapper .carousel .control-dots .dot {
    height: 6px;
    width: 6px;
    margin: 0 4px;
}
.feedbackOverlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.34);
    z-index: 2;
}
.feedbackWrapper {
    width: 400px;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 42px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 3;
    transition: 1s;
}
.feedbackWrapperOpen {
    width: 400px;
    position: fixed;
    bottom: 0;
    right: 0;
    overflow: hidden;
    display: flex;
    margin-right: 42px;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    transition: 1s;
    transform: translateY(83%);
}
.feedbackContainer {
    width: 400px;
    height: 293px;
    padding: 10px 15px 18px 20px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.feedbackButtonWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-left: -20px;
}
.popupButton {
    width: 38px;
    height: 18px;
    background: #0058fa;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
}
.upButton img {
    width: 10px;
    height: 5px;
}
.popDownButton {
    width: 38px;
    height: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
}
.downButton img {
    width: 10px;
    height: 5px;
}
.feedbackHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.feedbackTitle {
    font-family: Mulish;
    font-size: 18px;
    font-weight: bold;
}
.feedbackClose img {
    width: 18px;
    cursor: pointer;
}
.feedbackSub {
    font-family: Mulish;
    font-size: 13px;
    text-align: left;
    color: #3a4354;
    opacity: 0.7;
    margin-bottom: 20px;
}
.feedbackSubmitWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.feedbackSubmitButton {
    width: 125px;
    height: 45px;
    background-color: #0058fa;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Mulish;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
}
.input__container {
    margin-bottom: 1.5em;
}
.gotagLogo {
    font-family: ChainsawGeometric;
    font-size: 2em;
    color: #0000a5;
    &__second_text {
        color: #0058fa;
    }
}

.blankholidayIcon {
    width: 70px;
    object-fit: contain;
    position: relative;
    bottom: 3em;
}
.noHolidayTitle {
    font-family: Mulish;
    font-size: 16px;
    color: #000000;
    text-align: center;
    margin: 20px 0 10px 0;
}
.goalsActivityWrapper {
    display: flex;
    align-items: center;
}
.goalsChart {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.goalsValueWrapper {
    height: 8em;
    align-items: center;
}
.stalledColor {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    background-color: #002770;
    margin-right: 10px;
}
.goalsValueRow {
    display: flex;
    margin-bottom: 20px;
}
.progressColor {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    background-color: #0058fa;
    margin-right: 10px;
}
.completedColor {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    background-color: #a2c3ff;
    margin-right: 10px;
}
.valueText {
    font-size: 14px;
    text-align: center;
    font-family: Mulish;
}
.valueNumber {
    font-weight: bold;
}
.goalchartCenterNumber {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 48%;
    align-items: center;
}
.goalsCenterVlaue {
    font-family: Mulish;
    font-size: 36px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
}
.goalCenterText {
    font-family: Mulish;
    font-size: 16px;
    text-align: left;
}

.slick-dots li.slick-active button:before {
    color: #0058fa !important;
}   
