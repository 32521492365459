@import '../../sass/main.scss';
.candidate_list_content_wrapper {
    @include flex__spacebetween;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #f3f4f7;
    border-radius: 4px;
}

.employee-rounded-icon-photo {
    display: block;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    // border: 1px solid $color-grey-light;
    object-fit: cover;
}
.profile-desc-photo {
    margin: 5px 0;
    @include flex-row__center;
}

.candidate_profile-desc-photo {
    // margin: 16px 0 15px 20px;
    @include flex-row__center;
}

.candidate_content_name_wrapper {
    padding-left: 10px;
}
.candidate_content_inprogress_text {
    font-family: Mulish;
    font-size: 14px;
    color: #0058fa;
}

.candidate_content_accepted_text {
    font-family: Mulish;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1dc219;
}

.action_text_container {
    width: 100%;
    font-family: Mulish;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #666666;
    text-align: center;
    cursor: pointer;
}

.action_box_container {
    padding: 9.5px 20px;
    width: 206px;
    position: absolute;
    top: 65%;
    // right: calc(26% + 10px);
    right: calc(26% + 2em);
    border-radius: 6px;
    box-shadow: 0 12px 34px 0 rgba(0, 0, 0, 0.15);
    border: solid 0 #000000;
    background-color: #ffffff;
    z-index: 1;
}

.inprogress_circle {
    width: 8px;
    height: 8px;
    background-color: #0058fa;
    border-radius: 50%;
    margin-right: 8px;
}

.pending_circle {
    width: 8px;
    height: 8px;
    background-color: #ffbb44;
    border-radius: 50%;
    margin-right: 8px;
}
.approved_circle {
    width: 8px;
    height: 8px;
    background-color: #1dc219;
    border-radius: 50%;
    margin-right: 8px;
}

.candidate_content_pending_text {
    font-family: Mulish;
    font-size: 14px;
    color: #ffbb44;
}

.candidate_content_approved_text {
    font-family: Mulish;
    font-size: 14px;
    color: #1dc219;
}
