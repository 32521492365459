.support_main_container {
    // padding: 20px 0px;
    max-height: calc(100vh - (5em + 1em + 2em + 1em + 1em));
    overflow-y: scroll;
}

.support-details__container {
    background-color: #fff;
    padding: 3.5rem;
    display: flex;
    flex-direction: column;
}

.contact-support_row {
    display: flex;
    flex-direction: row;
}

.support-details__text-container_email {
    margin-right: 10em;
}

.support-details__text-container {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: #f3f4f8;
}

.support-details__text-key {
    font-family: Mulish;
    font-size: 12px;
    color: #696969;
    margin-bottom: 15px;
}

.support-details__text-value {
    font-family: Mulish;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    color: #404b5b;
    margin-bottom: 25px;
}

.support-details__text-value-last {
    font-family: Mulish;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    color: #404b5b;
}

.support_sub_title_margin {
    margin-top: 25px;
}
.preWS {
    // white-space: pre;
    line-break: anywhere;
}
