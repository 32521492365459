.asset-details__container {
    background-color: #fff;
    padding: 2.5rem;
    padding-bottom: 0;
    display: flex;
}
.asset-details__container > div {
    flex: 1;
    text-transform: capitalize;
}
.asset-details__text-key {
    font-family: Mulish;
    font-size: 14px;
    color: #696969;
    margin-bottom: 8px;
}
.asset-details__text-value {
    font-family: Mulish;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    color: #3a4354;
    margin-bottom: 40px;
}
.r-padding{
    padding-right: 20px;
}
.email_lowerCase {
    text-transform: lowercase;
}
.asset-details__modal-container {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
}
.asset-details__modal-delete-container {
    padding: 1.3125em 6.78125rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.asset-details__modal-icon {
    display: flex;
    justify-content: center;
}
.pdf-viewer {
    overflow: scroll;
    height: 54rem;
}
.asset-details__modal-title {
    font-family: Mulish;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    text-transform: capitalize;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.asset-details__modal-delete-title {
    font-family: Mulish;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    text-transform: capitalize;
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.asset-details__modal-text {
    font-family: Mulish;
    font-size: 1rem;
    margin-bottom: 20px;
}
.asset-details__modal-delete-text {
    font-family: Mulish;
    font-size: 1rem;
    // margin-bottom: 20px;
    text-align: center;
}
.asset-details__modal-button-container {
    display: flex;
    justify-content: flex-end;
}
.asset-details__modal-button-container > div {
    margin-left: 10px;
}
.asset-details__modal-search-bar {
    width: 30vw;
    height: 37px;
    border-radius: 2px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 30px;
}
.asset-details__modal-search-bar:focus-within {
    border-bottom: 1px solid #0058fa;
}
.asset-details__modal-input {
    background-color: transparent;
    border: none;
    padding-left: 10px;
    font-family: Mulish;
    font-size: 16px;
    color: #444444;
    width: 100%;
    outline: none;
}

.asset-details__modal-input::placeholder {
    font-family: Mulish;
    font-size: 14px;
    opacity: 0.5;
    font-weight: normal;
    color: '#404b5b';
}

.asset-details__modal-button-container {
    display: flex;
    margin-top: 30px;
}
.noUser-Asset {
    transform: translateY(-17px);
    padding: 9px 0px;
    height: 9px;
    font-size: 12px;
    line-height: 16px;
    // font-weight: bold;
    color: #f00;
    font-family: Mulish;
}
.blueName {
    font-family: Mulish;
    font-size: 14px;
    color: #0058fa;
    line-height: 18px;
    margin-bottom: 40px;
    font-weight: bold;
}
.longLineBreak {
    word-break: break-all;
}
.asset-details__container-row-box {
    padding-right: 20px;
}
.asset-details__container-row {
    display: flex;
}
.asset-details__container-row > div {
    width: 50%;
}
.flexColumn {
    flex-direction: column;
}
.asset-details__container-row_image {
    margin-bottom: 40px;
}
// .asset-details_image_container {
//     display: flex;
// }
.asset-details_Allimages_Container {
    display: flex;
    margin-top: 10px;
}
.asset-details_image_Container {
    width: 100px;
    height: 100px;
    padding: 7px 7px 8px 8px;
    border: solid 1px #c4c4c4;
    margin-right: 15px;
}

.asset-details_image {
    width: 85px;
    height: 85px;
    object-fit: cover;
}

.asset-image_modal_container {
    width: 850px;
    height: 620px;
    background-color: #fff;
}
.carousel_close_icon {
    width: 25px;
    height: 25px;
}
.asset_carousel_image_container {
    width: 680px;
    height: 433px;
    margin: auto;
}
.carousel img {
    height: 100%;
    object-fit: cover;
}
.carousel .thumbs {
    display: flex;
    justify-content: center;
}
.carousel .thumb {
    width: 100px !important;
    height: 100px;
    border: 1px solid #c4c4c4 !important;
    padding: 7px 7px 8px 8px !important;
    margin-right: 15px !important;
}
.carousel .thumb img {
    width: 85px;
    height: 85px;
}
.carousel .control-next.control-arrow:before {
    content: '';
    border: solid #696969;
    // border-width: 0 3px 3px 0;
    border-top: none !important;
    border-right: 2px solid #696969;
    border-bottom: 2px solid #696969 !important;
    border-left: none !important;
    display: inline-block;
    padding: 10px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
    content: '';
    border: solid #696969;
    // border-width: 0 3px 3px 0;
    border-top: none !important;
    border-right: 2px solid #696969 !important;
    border-bottom: 2px solid #696969 !important;
    border-left: none;
    display: inline-block;
    padding: 10px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.carousel.carousel-slider .control-arrow:hover {
    background: none !important;
}
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    opacity: 1 !important;
}
