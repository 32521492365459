@import '../../sass/main.scss';

.employee_profile_container {
    padding: 1.66em;
}
.employee_details_bottom_container {
    display: flex;
    flex-direction: column;
    margin-top: 2.5em;
    &__left {
        width: 100%;
    }
    &__right {
        width: 100%;
    }
}

.employee_scroll_joinee {
    max-height: calc(100vh - (5em + 1em + 2em + 2em));
    overflow-y: scroll;
    // padding: 0rem 3rem;
}
.employee-details__assets-container {
    background-color: #fff;
    margin-top: 2.5em;
    padding: 40px;
}
.rehire-button {
    min-width: 145px;
    min-height: 35px;
    background-color: #0058fa;
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Mulish;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
    text-transform: uppercase;
}
.rehire-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}
