@mixin flex-row__center {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin flex-row1__center {
    display: flex;
    justify-content: center;
}
@mixin flex-col__center {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
@mixin flex-col__spacebetween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@mixin flex__spacebetween {
    display: flex;
    justify-content: space-between;
}
@mixin flex__spacebetween_center{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flex__start {
    display: flex;
    justify-content: flex-start;
}

@mixin flex__end {
    display: flex;
    justify-content: flex-end;
}

@mixin flex-col__start {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

@mixin flex-col__end {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

@mixin flex_align_center {
    display: flex;
    align-items: center;
}
