.assets-listing__table td {
    font-size: 14px;
    color: #3a4354;
}
.assets-listing__table td:first-child {
    //  padding-left: 1.2em;
    padding-left: 1.9em;
}
// .assets-listing__table td:first-child,
// .assets-listing__table td:nth-child(4) {
//     font-family: Mulish;
//     font-size: 14px;
//     color: #0058fa;
//     // padding-left: 1.9em;
// }
.assetAssignedFirstColumn {
    display: flex;
    flex-direction: row;
    width: 255px;
}
.assets-photo{
    margin-right: 5px;
}
.assetAssignedFirstColumnMiddle {
    margin-right: 37px;
    // margin-left: 14px;
    width: 40px;
}
.alignSelfAsset {
    align-self: center;
}
.assigned_model {
    overflow: hidden;
    max-width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.marginRight-15 {
    margin-right: 15px;
}
.Asset_image_attachment {
    color: #0059fb;
    text-align: center;
    width: 9em;
}
