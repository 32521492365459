.event-list-container {
    width: 100%;
    max-height: 70vh;
    overflow-y: scroll;
}

.events__breadcrumbs-container {
    display: flex;
}

.event_header_sorter {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 15px;
    border-bottom: solid 1px #e8e8e8;
}

.show-more {
    color: #0058fa;
    display: flex;
    justify-content: center;
    font-size: 13px;
    margin-top: 10px;
}

.sort_by_box {
    font-family: Mulish;
    font-size: 14px;
    font-weight: bold;
    color: #0058fa;
    margin-right: 0.5em;
}

.active_sort_text {
    color: #0058fa;
}

.sort_by_box_container {
    padding: 10px 15px;
    width: auto;
    position: absolute;
    top: 50%;
    right: 0%;
    /* border-radius: 6px; */
    box-shadow: 0 12px 34px 0 rgba(0, 0, 0, 0.15);
    border: solid 0 #000000;
    background-color: #ffffff;
    z-index: 1;
}
