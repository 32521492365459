@import '../sass/main.scss';
@import '~bootstrap/scss/bootstrap';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

* {
    box-sizing: border-box;
    scrollbar-width: none !important;
}

body {
    min-width: 1024px;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.cursor_pointer {
    cursor: pointer;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

:focus {
    outline: none;
}

/* Global styles */

html {
    font-size: 12px;
    height: 100%;
    width: 100%;
}

html body {
    margin: 0;
    height: 100%;
    width: 100%;
}

/* Basic Colors */

.blue {
    color: #0058fb;
}

.blue-bg {
    background-color: #0058fb !important;
}

.white {
    color: #ffffff !important;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.whiteBg {
    background-color: #ffffff !important;
}

.app {
    text-align: center;
}

/* flex classes */

.nowrap-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.flex-col {
    flex-direction: column;
}

.flex-col-reverse {
    flex-direction: column-reverse;
}

.flex-row {
    flex-direction: row;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.flex-around {
    justify-content: space-around;
}

.flex-between {
    justify-content: space-between;
}

.flex-start {
    justify-content: flex-start;
}

.flex-end {
    justify-content: flex-end;
}

.cursor-pointer {
    cursor: pointer;
}

.flex-center {
    justify-content: center;
}

.flex-align-center {
    align-items: center;
}

.flex-align-start {
    align-items: flex-start;
}

.flex-align-end {
    align-items: flex-end;
}

.flex-align-baseline {
    align-items: baseline;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-flow-wrap {
    flex-flow: wrap;
}

.side_nav_left_container {
    // width: 20%;
    min-width: 260px;
    height: 100%;
    box-shadow: 2px 2px 2px 0 rgba(172, 181, 189, 0.15);
    z-index: 1;
}

.bread_crumbs_container {
    background-color: #f3f4f8;
    width: 100%;
    padding: 1em 3.58em;
    border-bottom: solid 1px #e8e8e8;
    // top: 0;
    margin-bottom: 1em;
    position: sticky;
    z-index: 0;
}

.content_right_container {
    // width: 80%;
    // width: 83%;
    width: calc(100% - 260px);
    // height: 100%;
    overflow: auto;
    overflow-x: hidden;
}

//fonts added

.Header-1_H1 {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 1.92em;
    color: #000000;
}

.Header-1_Contact {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 1.4em;
    line-height: 1.92em;
    color: #000000;
}

.Header-1_Profile-Edit {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 1.65em;
    line-height: 1.92em;
    color: #000000;
}

.Header-2_H2 {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5em;
    line-height: 1.92em;
    /* identical to box height */

    text-align: center;

    color: #000000;
}

.Header-3_H3G {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 1.33em;
    line-height: 130.6%;
    /* identical to box height, or 21px */

    color: #27c224;
}

.Navigation---Default-State_Nav1 {
    font-family: Mulish;
    font-weight: 600;
    font-style: normal;
    font-weight: normal;
    font-size: 1.08em;
    line-height: 1.5em;
    color: #3d4b5b;
}

.Navigation---Active-State_Nav1 {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 1.08em;
    line-height: 1.5em;
    color: #0058fa;
}

.Secondary-Navigation---Default-State_N2 {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    line-height: 1.25em;
    /* identical to box height */

    color: #636c72;
}

.Secondary-Navigation---Active-State_N2 {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    line-height: 1.25em;
    /* identical to box height */

    color: #0058fa;
}

.body_B {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 1.167em;
    line-height: 1.5em;
    /* identical to box height */
    color: #666666;
}

.body_B_alt {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 1.167em;
    line-height: 1.5em;
    /* identical to box height */
    color: #000000;
}

.Body-1_B1 {
    font-size: 14px;
    font-weight: bold;
    color: #3a4354;
    font-family: Mulish;
    font-style: normal;

    line-height: 1.5em;
    /* identical to box height */
}

.Body-1_B1_alt {
    font-family: Mulish;
    font-style: normal;
    // font-weight: 600;
    font-size: 1.167em;
    line-height: 1.5em;
    /* identical to box height */
    color: #000000;
    width: 85%;
}

.Body-1_B1_comment {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    line-height: 1.5em;
    /* identical to box height */
    color: #000000;
}

.Body-1-Semibold_B1S {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 1.167em;
    line-height: 1.5em;
    color: #3a4354;
}

.Body-2_B2 {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 1.167em;
    line-height: 1.5em;
    color: #444444;
}

.Body-3_B3 {
    font-style: normal;
    font-weight: normal;
    font-family: Mulish;
    font-size: 12px;
    color: #696969;
}

.Body-3_Profile_Desc {
    font-style: normal;
    font-weight: normal;
    line-height: 1.5em;
    font-family: Mulish;
    font-size: 0.9em;
    color: #696969;
}

.Body-3_C {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 1.5em;
    /* identical to box height */
    color: #696969;
}

.Body-4_B4 {
    font-family: Mulish;
    font-style: normal;
    font-weight: 500;
    font-size: 1.167em;
    line-height: 1.5em;
    /* identical to box height */
    color: #0058fa;
}

.Body-4_Nav {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 1em;
    line-height: 1.5em;
    /* identical to box height */
    color: #0058fa;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding-bottom: 0.5em;
}

.body_B_default_Nav {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 1em;
    line-height: 1.5em;
    /* identical to box height */
    // color: #666666;
}

.Table-header_TH {
    font-family: Mulish;
    font-size: 0.833em;
    color: #929292;
    line-height: 1.25em;
    text-transform: uppercase;
}
.Table-header_TH-onClick {
    font-family: Mulish;
    font-size: 0.833em;
    color: black;
    line-height: 1.25em;
    text-transform: uppercase;
}

.Table-header_TH:hover {
    cursor: pointer;
    color: black;
}
.Table-header_TH-onClick:hover {
    cursor: pointer;
}

.ActionTable-header_TH {
    font-family: Mulish;
    font-size: 0.833em;
    color: #929292;
    line-height: 1.25em;
    text-transform: uppercase;
    white-space: nowrap;
}

.Body-bold_Bb {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 1.167em;
    line-height: 1.5em;
    color: #000000;
}

.Body-tiny-Green_BtG {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 1.5em;
    text-align: right;
    /* identical to box height */
    color: #1dc219;
}

.Body-tiny-Grey_BTG {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 1.25em;
    color: #666666;
}

.Body-tiny-light_BtL {
    font-family: Mulish;
    font-style: normal;
    font-weight: 300;
    font-size: 1em;
    line-height: 163%;
    /* identical to box height, or 20px */
    color: #444444;
}

.Body-tiny-Regular_BtR {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 163%;
    /* identical to box height, or 20px */
    color: #444444;
}

.Button-Text_BT {
    font-family: Mulish;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    /* identical to box height */
    color: #ffffff;
}

.Body-tiny-2_Bt2 {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 1.25em;
    /* identical to box height */
    color: #ff7474;
}

.Body-edit-tabs {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 1.25em;
    /* identical to box height */

    color: #0058fa;
}

.Link1 {
    font-family: Mulish;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0058fa;
}

.Link1_button {
    font-family: Mulish;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0058fa;
}

.modal_loader_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_loader_content {
    width: 8.33em;
    height: 8.33em;
}

.modal_loader_content_save {
    width: 4.33em;
    height: 4.33em;
}

.not_found_text {
    font-size: 20px;
    font-family: Mulish;
    color: #000;
}

.not_found_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
