.create-asset__container {
    display: flex;
}

.create-asset__container > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0;
}

.create-asset__container > div:first-child {
    padding-right: 8vw;
}
.firstLineOfNote {
    font-family: Mulish;
    color: #000000;
    font-size: 16px;
}
